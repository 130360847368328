import { TUnionRepo } from "@netvision/lib-api-repo";
import { cameraCommands } from "../types";
import { isLibApiRepo } from "../utils";

type CameraBlockCommands = keyof typeof cameraCommands

export type TCommands = Record<
    CameraBlockCommands,
    (
        cameras: string[],
        commandExtraData?: Record<string, number | string>,
    ) => ReturnType<typeof buildCameraCommand>
>

const buildCameraCommand = async (
    api: TUnionRepo,
    command: string,
    cameras?: string[],
    commandExtraData?: Record<string, number | string>,
) => {
    if (!isLibApiRepo(api)) return
    if (!cameras || !cameras.length) return
    const promises = cameras.map((id) => api.updateEntity({
        id,
        type: 'Camera',
        [command]: {
            ...commandExtraData
        }
    }))

    return await Promise.all(promises)
}

export const commands = (api: TUnionRepo): TCommands => {
    return Object.fromEntries(
        Object.values(cameraCommands).map((commandName) => [
            commandName,
            buildCameraCommand.bind(null, api, commandName),
        ]),
    ) as TCommands
} 


