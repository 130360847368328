import { ISearchQueryPayload, TUnionRepo } from '@netvision/lib-api-repo'
import { CameraPermissionScopes, CameraLocking } from '../types'
import { Observer } from '../utils'
import { commands, type TCommands } from './commands'
import {
  getPermissionInfo,
  isControlsLockedNow,
  isStreamLockedNow,
  TPermissionInfo
} from './permissionsInfo'
import { isLibApiRepo } from '../utils'

interface ICamera {
  isLoading: boolean
  permissionsScopes: null | CameraPermissionScopes[]
  cameraLocking: null | CameraLocking[]
}

export interface ILockingCamera {
  cameraId: string
  isLoading: boolean
  lockingData: {
    id: string | undefined
    status: 'Creation' | 'Active' | 'Canceled' | 'Error' | 'Finished' | undefined
    period: number
    start: number
  } | null
  canI: null | TPermissionInfo['canI']
  is: null | TPermissionInfo['is']
}

export class LockingCamera {
  private api: TUnionRepo | null = null
  private userId: string | null = null
  private observer = new Observer()
  private cameras: Map<string, ICamera> = new Map()
  private commands: TCommands | null = null
  private socket: any = null

  public getCameraLocking(api: TUnionRepo, cameraIds: string[]) {
    this.setApi(api)
    this.setCommands(api)
    this.setSocketConnection(api)

    const newCameras = this.findNewCameras(cameraIds)

    const promise = Promise.resolve()

    if (newCameras.length > 0) {
      this.changeCamerasMap(newCameras, { isLoading: true }, 'set')

      promise
        .then(() =>
          Promise.all([
            this.getUserId(api),
            this.getPermissionsMap(api, newCameras),
            this.getLockingEntity(api, newCameras)
          ])
        )
        .catch(() => {
          this.changeCamerasMap(newCameras, { isLoading: true })
        })
    }

    promise?.then(() => {
      this.changeCamerasMap(cameraIds, { isLoading: false })
    })

    return {
      subscribe: this.observer.subscribe(cameraIds)
    }
  }

  public async locked(cameraIds: string[], periodInSeconds: number) {
    if (cameraIds.every((id) => !this.cameras.get(id)?.permissionsScopes)) return

    for (const id of cameraIds) {
      const camera = this.cameras.get(id)
      if (camera) {
        const permissionInfo = getPermissionInfo(
          camera.permissionsScopes,
          camera.cameraLocking,
          cameraIds
        )
        const notMyLockedCamera = camera.cameraLocking
          ?.filter((lockingInfo) => {
            const { ownerId } = lockingInfo
            if (permissionInfo.canI.watchLockedStreams && isControlsLockedNow(lockingInfo))
              return false
            return ownerId !== this.userId
          })
          .map(({ cameraId }) => cameraId)

        const ownCameraId = cameraIds.filter((id) => !(notMyLockedCamera || []).includes(id))
        try {
          if (permissionInfo?.canI.watchLockedStreams) {
            await this.commands?.lockStreams(ownCameraId, { periodInSeconds })
          }
          if (
            !permissionInfo?.canI.watchLockedStreams &&
            permissionInfo?.canI.changeLockedControls
          ) {
            await this.commands?.lockControl(ownCameraId)
          }
        } catch (e) {
          console.error(e)
          throw e
        }
      }
    }
  }
  public unlocked(cameraIds: string[]) {
    return new Promise<void>((resolve, reject) => {
      if (cameraIds.every((id) => !this.cameras.get(id)?.permissionsScopes)) return

      const makeUnlock = async (
        permissionsScopes: CameraPermissionScopes[] | null,
        cameraLocking: CameraLocking[] | null,
        cameraIdsList: string[]
      ) => {
        const permissionInfo = getPermissionInfo(permissionsScopes, cameraLocking, cameraIdsList)
        // @ts-ignore
        if (this.api?.addOnBadRequestListener) {
          // @ts-ignore
          const unsubscribe = this.api?.addOnBadRequestListener(async (response: Response) => {
            const isJsonResponse = response.headers.get('content-type')
            if (isJsonResponse?.includes('application/json;')) {
              const r = await response.json()
              reject(new Error(r.description))
            }
            unsubscribe()
          })
        }

        try {
          let actualCameraLocking = cameraLocking?.filter(
            (data) => isStreamLockedNow(data) || isControlsLockedNow(data)
          )
          if (!actualCameraLocking || !cameraLocking) return
          if (cameraLocking?.length > 1) {
            actualCameraLocking = actualCameraLocking.filter(
              ({ ownerId }) => ownerId === this.userId
            )
          }
          const actualCamerasId = actualCameraLocking.map(({ cameraId }) => cameraId)
          if (permissionInfo.canI.watchLockedStreams)
            await this.commands?.unlockStreams(actualCamerasId)
          if (!permissionInfo.canI.watchLockedStreams && permissionInfo.canI.changeLockedControls) {
            await this.commands?.unlockControl(actualCamerasId)
          }
          resolve()
        } catch (e) {
          console.error(e)
          throw e
        }
      }

      for (const id of cameraIds) {
        if (this.cameras.has(id)) {
          const camera = this.cameras.get(id) as ICamera
          makeUnlock(camera.permissionsScopes, camera?.cameraLocking, cameraIds)
        }
      }
    })
  }

  private findNewCameras(cameraIds: string[]) {
    return cameraIds.filter((id) => !this.cameras.get(id))
  }

  private setApi(api: TUnionRepo) {
    if (!this.api && !api) throw new Error('api is required')
    if (!this.api) this.api = api
  }

  private setCommands(api: TUnionRepo) {
    if (!this.commands) this.commands = commands(api)
  }

  private setSocketConnection(api: TUnionRepo) {
    if (!isLibApiRepo(api)) return
    if (!this.socket || !this.isSocketConnected()) {
      this.socket = api.getNotificationSocket()
      this.socket.addListener('CameraLocking', this.updateCameraLockingBySocket.bind(this))
    }
  }

  private isSocketConnected() {
    return !this.socket?._socket?._destroyed
  }

  private setLockingInfoTypeArray(cameraIds: string[]) {
    const result = new Map<string, any>()
    for (const id of cameraIds) {
      const camera = this.cameras.get(id)
      if (camera && typeof camera.permissionsScopes !== 'undefined') {
        const hasLockingData = camera.cameraLocking?.length && Array.isArray(camera.cameraLocking)
        result.set(id, {
          cameraId: id,
          isLoading: camera.isLoading,
          lockingData: hasLockingData
            ? {
                id: camera.cameraLocking?.[0].id,
                status: camera.cameraLocking?.[0].state,
                period: (camera.cameraLocking?.[0].periodInSeconds || 0) * 1000,
                start: camera.cameraLocking?.[0].startDt || 0
              }
            : null,
          ...getPermissionInfo(camera.permissionsScopes, camera.cameraLocking, [id])
        })
      }
    }
    return result
  }

  private changeCamerasMap(
    cameraIds: string[],
    data: Partial<ICamera>,
    type: 'update' | 'set' = 'update'
  ) {
    cameraIds.forEach((id) => {
      if (type === 'set') {
        this.cameras.set(id, data as ICamera)
      } else {
        this.cameras.set(id, { ...this.cameras.get(id), ...data } as ICamera)
      }
    })

    this.observer.notify(cameraIds, this.setLockingInfoTypeArray(cameraIds))
  }

  private async getUserId(api: TUnionRepo) {
    if (!isLibApiRepo(api) || this.userId) {
      return
    }
    const userIdFromStorage = window.localStorage.getItem('netvision:user-id')

    if (!userIdFromStorage) {
      const { userId } = await api.getUserInfo()
      this.userId = userId
    } else {
      this.userId = userIdFromStorage
    }
  }

  private async getPermissionsMap(api: TUnionRepo, cameraIds: string[]) {
    if (!cameraIds.length) return
    if (!isLibApiRepo(this.api)) return

    try {
      // @ts-ignore
      const permissionMap = await api.getPermissionWithGlobalBatch(
        cameraIds.map((id) => ({
          entityId: id,
          scopes: Object.values(CameraPermissionScopes)
        })),
        'lib-camera-locking'
      )

      cameraIds.forEach((id) => {
        this.changeCamerasMap([id], {
          permissionsScopes: permissionMap.get(id).scopes
        })
      })
    } catch (e) {
      this.changeCamerasMap(cameraIds, {
        permissionsScopes: null
      })
      console.error(e)
    }
  }

  private async getLockingEntity(api: TUnionRepo, cameraIds: string[]) {
    if (!cameraIds.length) return
    try {
      const cameraLocking = await this.updateCameraLocking(api, cameraIds)

      if (!cameraLocking) {
        this.changeCamerasMap(cameraIds, { cameraLocking: null })
        return
      }

      cameraIds.forEach((id) => {
        this.changeCamerasMap([id], {
          cameraLocking: cameraLocking.filter(({ cameraId }) => cameraId === id)
        })
      })
    } catch (e) {
      this.changeCamerasMap(cameraIds, { cameraLocking: null })
      console.error(e)
    }
  }

  private async updateCameraLocking(api: TUnionRepo, cameraIds: string[]) {
    // @ts-ignore
    const { results } = (await api.getMergedQueryBatch<CameraLocking>({
      limiter: {
        type: 'CameraLocking'
      },
      filter: {
        q: [
          {
            key: 'cameraId',
            operator: '==' as ISearchQueryPayload['operator'],
            value: cameraIds.join(',')
          },
          {
            key: 'state',
            operator: '==',
            value: 'Active,Creation'
          }
        ]
      }
    })) as { results: CameraLocking[] }

    const filteredResults = results.filter(({ cameraId }) => cameraIds.includes(cameraId))
    return filteredResults
  }

  private updateCameraLockingBySocket(cameraLockingData: CameraLocking) {
    const hasThisId = this.cameras.has(cameraLockingData.cameraId)
    if (!hasThisId) return
    const camera = this.cameras.get(cameraLockingData.cameraId) as ICamera

    const cameraLocking = (() => {
      const prev = camera.cameraLocking
      if (Array.isArray(prev)) {
        const indexCameraLocking = prev.findIndex(
          (data) => data.cameraId === cameraLockingData.cameraId
        )

        if (indexCameraLocking !== -1) {
          return prev?.map((entity, index) => {
            if (index === indexCameraLocking) return { ...cameraLockingData }
            return entity
          })
        }
        return [...prev, cameraLockingData]
      }
      return [{ ...cameraLockingData }]
    })()

    this.changeCamerasMap([cameraLockingData.cameraId], { cameraLocking })
  }
}
