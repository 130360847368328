import { CameraLocking, CameraPermissionScopes, cameraCommands } from "../types"


export const getPermissionInfo = (
    permissionsScopes: null | CameraPermissionScopes[],
    cameraLocking: null | CameraLocking[],
    cameraIds: string[],
) => {
    const changeLockedControls =
        permissionsScopes &&
        cameraIds.length > 0 &&
        isLockedControls(permissionsScopes)
    const watchLockedStreams =
        permissionsScopes &&
        cameraIds.length > 0 &&
        isLockedStreams(permissionsScopes)

    return {
        canI: {
            someLocked: watchLockedStreams || changeLockedControls,
            watchLockedStreams,
            changeLockedControls,
        },
        is: {
            streamLockedNow: cameraLocking?.some(isStreamLockedNow),
            controlsLockedNow: cameraLocking?.some(isControlsLockedNow),
        },
    }
}

export type TPermissionInfo = ReturnType<typeof getPermissionInfo>

const isActiveOrCreationState = (cameraLocking: CameraLocking | null) => {
    if (!cameraLocking) return false
    return (['Creation', 'Active'] as CameraLocking['state'][]).includes(cameraLocking?.state)
}

const isLockedControls = (permissionsScopes?: CameraPermissionScopes[]) =>
    permissionsScopes?.includes(CameraPermissionScopes.LockControl) &&
    permissionsScopes?.includes(CameraPermissionScopes.UnlockControl)

const isLockedStreams = (permissionsScopes?: CameraPermissionScopes[]) =>
    permissionsScopes?.includes(CameraPermissionScopes.UnlockStreams) &&
    permissionsScopes?.includes(CameraPermissionScopes.LockStreams)

export const isStreamLockedNow = (cameraLocking: CameraLocking | null) =>
    [cameraCommands.lockStreams].every((c) => cameraLocking?.scopes.includes(c)) &&
    isActiveOrCreationState(cameraLocking)

export const isControlsLockedNow = (cameraLocking: CameraLocking | null) => {
    return (
        [cameraCommands.lockControl].every((c) => cameraLocking?.scopes.includes(c)) &&
        isActiveOrCreationState(cameraLocking)
    )
}