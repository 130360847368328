export interface ILockingInfo {
    locked: boolean
}


export const cameraCommands = {
    lockControl: 'lockControl',
    unlockControl: 'unlockControl',
    lockStreams: 'lockStreams',
    unlockStreams: 'unlockStreams',
} as const

export enum CameraPermissionScopes {
    LockControl = 'LockControl',
    UnlockControl = 'UnlockControl',
    UnlockStreams = 'UnlockStreams',
    LockStreams = 'LockStreams',
}

export interface CameraLocking {
    id: string
    type: 'CameraLocking'
    allowedGroups: string[]
    allowedUsers: string[]
    cameraId: string
    createdStreams: []
    periodInSeconds: number
    endDt: null | number
    ownerId: string
    scopes: keyof typeof cameraCommands
    startDt: number
    state: 'Creation' | 'Active' | 'Canceled' | 'Error' | 'Finished'
}
