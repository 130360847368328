export class Observer {
    private subscribers: Map<string, any> = new Map()
    subscribe(keys: string[] ) {
        return (cb: any) => {
            if (!keys) return
            keys.forEach(key => {
                if (!this.subscribers.has(key)) {
                    this.subscribers.set(key, [])
                }
                this.subscribers.set(
                    key,
                    [...this.subscribers.get(key), cb]
                )
            })
            return this.unsubscribe(keys)
        }
    }
    unsubscribe(keys: string[]) {
        return () => {
            if (!keys) return
            keys.forEach(key => this.subscribers.delete(key))
        }
    }

    notify(keys: string[], data: Map<string,any>) {
        keys.forEach(key => {
            if (!this.subscribers.has(key) || !data.has(key)) return
            this.subscribers.get(key).forEach((cb: any) => cb(data.get(key)))
        })
    }
}